
.c-circle {
    border: 1px solid rgb(217, 108, 19);
    border-radius: 50px;
    display: inline-block;
    line-height: 22px;
    font-size: 12px;
    height: 25px;
    text-align: center;
    width: 25px;
    cursor: pointer;
    background-color:transparent
}

.subtle {
    color: rgb(217, 108, 19);
}
  

