.showreview{
    background-color: bisque;
    padding:10vh;
}

.sreview{
    margin-top: 1%;
    height:80%;
    font-size: medium;
}

.showpic{
    height:30vh;
}