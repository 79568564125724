.showfoodsbody{
 padding-bottom: 10vh;
 background-color: bisque;
}

.foods{
  display:flex;
  flex-wrap: wrap;
  margin-left:1%;
}

*, *:before, *:after {
    box-sizing: inherit;
  }

  
  .subtle {
    color: rgb(217, 108, 19);
  }
  
  .c-container {
    margin: 25px auto 0;
    text-align: left;
    border:1px solid black;
  }
  
  .c-body {
    max-height: 60vh;
    width:28vw; 
    display:grid;
    border: 1px solid #aaa;
    grid-template-rows: 1fr 3fr 4fr 2fr 1fr 2fr;
    grid-template-columns: 1fr;
    padding:1vw;
  }
    
  
  
  .c-number {
    grid-area: 1/1/2/2;
    margin-top: 15px;
    font-size: medium;
  }
  
  .c-circ {
    border: 1px solid rgb(217, 108, 19);
    border-radius: 50px;
    display: inline-block;
    line-height: 22px;
    font-size: small;
    height: 25px;
    text-align: center;
    width: 25px;
    color:black;
    cursor: pointer;
    grid-area: 6/1/7/2;
  }
  
  .c-author {
    display: block;
    font-size: small;
    letter-spacing: .5px;
    margin: 15px 0 0;
    text-transform: uppercase;
    grid-area: 4/1/5/2;
    color:rgb(217, 108, 19);
  }
  
  .c-title {
    grid-area: 2/1/3/2;
    font-size: x-large;
    font-weight: 300;
    line-height: 40px;
    margin: 10px 0;
  }
  
  .c-read {
    cursor: pointer;
    font-size: small;
    font-weight: 700;
    letter-spacing: 6px;
    margin: 5px 0 20px;
    position: relative;
    text-align: right;
    grid-area: 5/1/6/2;
  }
  
  .c-read:after {
    background-color: rgb(217, 108, 19);
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 9px;
    width: 45%;
  }

  .c-media {
    grid-area: 3/1/4/2;
    height:19vh;
  }

  ul{
    list-style-type: none;
  }

  .link{
    text-decoration: none;
    color:black;
  }

 