  .reviewsbody{
    background-color: bisque;
    padding-top:1vh;
    padding-bottom:10vh;
  }
  .craves{
    font-size: x-large;
    font-weight: 300;
  }

  h1{
    font-size: 30px;
    color: black;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;
  }
  table{
    width:100%;
    table-layout: fixed;
  }
  .tbl-header{
    background-color: rgba(15, 9, 9, 0.3);
   }
  .tbl-content{
    height:300px;
    overflow-x:auto;
    margin-top: 0px;
    
  }
  th{
    padding: 20px 15px;
    text-align: left;
    font-weight: 400;
    font-size: 19px;
    color: #fff;
    text-transform: uppercase;
  }
  td{
    padding: 15px;
    text-align: left;
    vertical-align:middle;
    font-weight: 400;
    font-size: 16px;
    color: black;
    border-bottom: solid 1px rgba(255,255,255,0.1);
  }
  
  section{
    margin: 50px;
  }
  
  .aname{
    text-decoration: none;
    color:black
  }
  

  
  
