
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
	background-color: rgb(217, 108, 19);
    font-family: 'Montserrat Alternates', sans-serif;
}

.navcontainer{
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height:8vh;
}

.nav{
    color:#FAF4DF;
    text-decoration: none;
    font-size: large;
}
.nav::before{
    color:aqua;
}
.nav:hover{
    color:rgb(217, 108, 19);
}

.auth{
    width:60vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logsign{
    width:60vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.log{
    margin-left: 70%;
}


/* width */
::-webkit-scrollbar {
    width: 2vh;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:black;
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:  #FAF4DF;; 
    border-radius: 10px;
  }
  
 .outbtn{
    background-color: transparent;
    color:#FAF4DF;
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: large;
    border-radius: 5px;
    border: 1px solid  rgb(217, 108, 19);
    padding:4px;
    cursor: pointer;
 }

 .outbtn:hover{
    color: rgb(217, 108, 19);
    border:1px solid white;
 }